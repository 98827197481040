import * as EmailValidator from 'email-validator';
import api from '@/api';
import { BASE_URL } from '@/constants';
import { pick } from '@/utils';
import { IUser, VerifyUser } from '@/models/auth';

export async function login(user: IUser): Promise<any> {
  const data = pick(['username', 'password'], user) as IUser;
  data.username = EmailValidator.validate(user.username) ? user.username.toLowerCase() : user.username;
  return api.post('/auth/login', data);
}

export async function loginBasic(user: IUser): Promise<any> {
  const data = pick(['username', 'password'], user) as IUser;
  data.username = EmailValidator.validate(user.username) ? user.username.toLowerCase() : user.username;
  return api
    .get('/auth/me', {
      auth: {
        username: data.username,
        password: data.password,
      },
    })
    .then((response: any) => response);
}

export async function logout(): Promise<any> {
  return api.get('auth/logout');
}

export async function register(user: IUser): Promise<IUser> {
  const payload = <any>pick(['name', 'username', 'password', 'email', 'cellNumber', 'shouldWelcome'], user);
  if (payload.username === '') delete payload.username;
  if (payload.email === '') delete payload.email;
  if (payload.cellNumber === '') delete payload.cellNumber;
  if (payload.name === '') delete payload.name;
  if (payload.password === '') delete payload.password;
  const redirectTo = `${BASE_URL}/otp-verification?mobile=${payload.cellNumber}`;
  if (redirectTo) {
    payload.redirectTo = redirectTo;
  }
  if (payload.email != null) payload.email = payload.email.toLowerCase();
  const { data } = await api.post('/auth/register', payload);
  return data as IUser;
}

export async function verify(vUser: VerifyUser): Promise<VerifyUser> {
  const { data } = await api.get(`/verify?token=${vUser.otp}`);
  return data;
}

export async function verifyMobile(vUser: VerifyUser): Promise<VerifyUser> {
  const { data } = await api.get(`/verify?token=${vUser.otp}&msisdn=${encodeURIComponent(vUser.cellNumber as string)}`);
  return data;
}

export async function getMe(): Promise<IUser> {
  const { data } = await api.get('auth/me');
  return data as IUser;
}

export async function getUser(id: string): Promise<IUser> {
  const { data } = await api.get(`users/${id}`);
  return data as IUser;
}

export async function validateBankDetails(id: string): Promise<IUser> {
  const { data } = await api.get(`/users/bankDetails/${id}`);
  return data as IUser;
}

export async function forgotPassword(redirectTo: string, value: string, channel: 'EMAIL' | 'MOBILE') {
  const response = await api.post('request-password-reset', {
    redirectTo,
    value: value.toLowerCase(),
    channel,
  });

  return response;
}
