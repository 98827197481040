import api from '@/api';
import { IMerchant } from '@/models/merchants';

export async function getMerchant(id: string): Promise<IMerchant> {
  const { data } = await api.get(`/terms/${id}`);
  return data as IMerchant;
}

export async function getShopDirectory(): Promise<any> {
  const { data } = await api.get(`/shop-directory`);
  return data;
}

export async function getFeaturedStores(): Promise<any> {
  const { data } = await api.get(`/shop-directory?featured=true`);
  return data;
}
