import dayjs from '@/utils/dayjs';
import { BILLING_TIME } from './constants';

export const times = (func: (i: number) => any, n: number) =>
  Array(n)
    .fill(0)
    .map((_, i) => func(i));

export const pick = (array: string[], collection: { [index: string]: any }) =>
  array.reduce((res: { [index: string]: any }, key: string) => {
    // eslint-disable-next-line no-param-reassign
    res[key] = collection[key];
    return res;
  }, {} as object);

export const last = (array: any[]) => array[array.length - 1];

export const getVisaType = (cardNumber: string) => {
  if (!cardNumber) return null;

  const prefix = cardNumber[0];

  if (prefix === '4') return 'VISA';
  if (prefix === '5') return 'MASTER';

  return null;
};

export const indexPostfix = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export function validateCardNumber(number: string): boolean {
  function luhnCheck(val: string): boolean {
    let sum = 0;
    for (let i = 0; i < val.length; i += 1) {
      let intVal = Number.parseInt(val.substr(i, 1), 10);
      if (i % 2 === 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return sum % 10 === 0;
  }

  const regex = /^[0-9]{16}$/;
  if (!regex.test(number)) {
    return false;
  }

  return luhnCheck(number);
}

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateMobile(mobile: string): boolean {
  if (!mobile) {
    return false;
  }
  const cleaned = mobile.replace(/[\s+()-.]/g, '');
  const isNumber = /^\d+$/.test(cleaned);
  if (!isNumber) {
    return false;
  }
  if (cleaned.length === 10 && cleaned[0] === '0') {
    return true;
  }
  return cleaned.length >= 9 && cleaned.length <= 13;
}

export const first = (array: any[]) => array[0];

/**
 * Assume mobile to have parsed 'validateMobile'
 */
export const parseMobile = (mobile: string) => {
  if (!mobile) {
    return mobile;
  }
  const cleaned = mobile.replace(/[\s+()-.]/g, '');

  const defaultCountryPrefix = '27';

  if (cleaned.length === 10 && cleaned[0] === '0') {
    return `${defaultCountryPrefix}${cleaned.slice(1)}`;
  }
  if (cleaned.length === 9) {
    return `${defaultCountryPrefix}${cleaned}`;
  }
  return cleaned;
};

export const currencyZAR = (amount: any, modifier = 100) => {
  if (amount === null) return '';
  const amountReg = amount.toString().match(/[\d.]+/);
  if (amountReg) {
    amount = amountReg[0];
  } else {
    amount = '';
  }

  return new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
  }).format(amount / modifier);
};

export const formatDate = (date: string | number, format: string = 'YYYY-MM-DD') => {
  if (!date) return null;
  return dayjs(date).format(format);
};

export const dueWeeks = (dueDate: string | number): string | number => {
  const date = dueDate ? dayjs(dueDate) : dayjs();

  const days = dayjs(date).diff(dayjs(), 'day');
  if (days <= 1) return 'OVER DUE';
  if (days <= 6) return `DUE IN ${days} DAYS`;
  if (days < 0) return 'PAYMENT FAILED';

  const weeks = dayjs(date).diff(dayjs(), 'week');
  if (weeks <= 4) return `DUE IN ${weeks} WEEK${weeks > 1 ? 'S' : ''}`;

  const quantity = dayjs(date).diff(dayjs(), 'month');
  return `DUE IN ${quantity} MONTH${quantity > 1 ? 'S' : ''}`;
};

export const checkIsBefore = (date: string | number) =>
  dayjs(date).isBefore(dayjs(), 'day') ||
  (dayjs(date).isSame(dayjs(), 'day') && dayjs().isAfter(dayjs(BILLING_TIME, 'hh:mm:ssZ')));

export const isValidIdNumber = (id: string): boolean => {
  const re =
    /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
  return re.test(id);
};

export const goToRoutePath = (router: any, url: string) => {
  router.push({ path: url });
};

export const onlyNumberKey = (e: any) => {
  const char = String.fromCharCode(e.keyCode);
  if (/^[0-9]+$/.test(char)) return true;
  e.preventDefault();
  return false;
};

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
