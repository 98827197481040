<template>
  <div v-if="fullScreen" class="overlay flex-center">
    <InlineLoader />
    <h3>Loading...</h3>
  </div>
  <InlineLoader v-else />
</template>

<script lang="ts">
import Vue from 'vue';
import InlineLoader from './InlineLoader.vue';

export default Vue.extend({
  components: {
    InlineLoader,
  },
  props: {
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
.overlay {
  background-color: #e8eff7;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
}

h3 {
  position: absolute;
  top: 0;
  right: 20px;
}
</style>
