import api from '@/api';
import { ICard } from '@/models/cards';

export async function createCard(card: ICard): Promise<ICard> {
  const { data } = await api.post('/cards', card);
  return data as ICard;
}

export async function getCards(): Promise<ICard[]> {
  const {
    data: { clientCards },
  } = await api.get('/cards');
  return (clientCards || []) as ICard[];
}

export async function getCard(id: string): Promise<ICard> {
  const { data } = await api.get(`/cards/${id}`);
  return data as ICard;
}

export async function deleteCard(id: string): Promise<void> {
  await api.delete(`/cards/${id}`);
}

export async function updateCard(card: ICard): Promise<ICard> {
  const { data } = await api.put(`/cards/${card._id}`, card);
  return data as ICard;
}
