<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :style="{ 'max-width': `${width}px` }">
          <div v-if="!hideClose" class="text-right">
            <button class="close" @click="$emit('close')">X</button>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    hideClose: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 520,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/styling/colors.scss';

.close {
  background: none;
  border: 1px solid lighten($light-grey, 10%);
  font-weight: bold;
  font-size: 11px;
  border-radius: 100%;
  padding: 4px 10px;
  &:hover {
    background-color: #f6f6f6;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 0 10px;
}

.modal-container {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
