import { setHeaderAuthorization } from '@/api';

export function setAuth(token: string): void {
  localStorage.setItem('layup-token', token);

  setHeaderAuthorization(token);
}

export function destroyToken(): void {
  localStorage.setItem('layup-token', '');
  setHeaderAuthorization('');
}

export function getToken(): string | null {
  return localStorage.getItem('layup-token');
}
