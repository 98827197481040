<template>
  <div class="checkbox">
    <input :id="name" v-model="localValue" type="checkbox" />
    <label :for="name"><slot /></label>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    localValue(val) {
      this.$emit('input', val);
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/styling/colors.scss';

.checkbox {
  cursor: pointer;

  height: 38px;
  display: inline-flex;
  align-items: center;
}
.checkbox input[type='checkbox'] {
  opacity: 0;
}

input[type='checkbox'] {
  display: none;
}

.checkbox label {
  position: relative;
  display: inline-block;
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
  line-height: 22px;
  cursor: pointer;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 16px;
  width: 16px;

  border: 1px solid lighten($light-grey, 10%);
  border-radius: 4px;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
    *to vertically center it.
    */
  top: 2px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 4px;
  width: 8px;
  border-left: 3px solid;
  border-bottom: 3px solid;

  transform: rotate(-45deg);

  left: 4px;
  top: 6px;
  border-color: #fff;
}

/*Hide the checkmark by default*/
.checkbox input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type='checkbox']:checked + label::before {
  /*     outline: rgb(59, 153, 252) auto 5px; */
  background: $light-blue;
  border-color: $light-blue;
}
</style>
