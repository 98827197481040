<template>
  <div tabindex="0">
    <transition name="fade">
      <Loader v-if="loading" full-screen />
      <router-view v-else />
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Loader from './components/Loader.vue';

export default Vue.extend({
  name: 'App',
  components: {
    Loader,
  },
  computed: {
    loading(): boolean {
      return this.$store.getters.loading;
    },
  },
});
</script>

<style lang="scss">
@import '@/styling/setup.scss';

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
