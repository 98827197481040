
<transition name="modal">
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" :style="{ 'max-width': `${width}px` }">
        <div v-if="!hideClose" class="text-right">
          <button class="close" @click="$emit('close')">X</button>
        </div>
        <slot />
      </div>
    </div>
  </div>
</transition>
