import axios from 'axios';
import { API_KEY, API_URL } from './constants';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Headers': 'Set-Cookie',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

axios.defaults.withCredentials = true;

if (API_KEY) {
  api.defaults.headers.apiKey = API_KEY;
}

export function setHeaderAuthorization(token: string): void {
  api.defaults.headers.Authorization = `token ${token}`;
}
export function unsetHeaderAuthorization(): void {
  delete api.defaults.headers.Authorization;
}

export function setHeaderAuthorizationBasic(token: string): void {
  api.defaults.headers.Authorization = `basic ${token}`;
}

export function unsetHeaderAuthorizationBasic(): void {
  delete api.defaults.headers.Authorization;
}

export default api;
