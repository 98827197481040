const {
  VITE_NOTIFY_URL: NOTIFY_URL,
  VITE_COPY_AND_PAY_WIDGET: CARD_PAYMENT_WIDGET,
  VITE_BASE_URL: BASE_URL,
  VITE_BILLING_TIME: BILLING_TIME,
  VITE_DEBICHECK_SIMULATION: DEBICHECK_SIMULATION,
  VITE_API_KEY: API_KEY,
  VITE_API_URL: API_URL,
  MODE,
  VITE_ENVIRONMENT: ENVIRONMENT,
  VITE_LAYUP_LOGO: LAYUP_LOGO,
  VITE_SPLIT_PAYMENT_REDIRECT_URL: SPLIT_PAYMENT_REDIRECT_URL,
  VITE_PASSWORD_RESET_REDIRECT_URL: PASSWORD_RESET_REDIRECT_URL,
  VITE_SHOW_BANK_DETAILS: SHOW_BANK_DETAILS,
  BASE_URL: UI_BASE_URL,
  VITE_DASHBOARDS_URL: DASHBOARDS_URL,
} = import.meta.env;

export {
  NOTIFY_URL,
  CARD_PAYMENT_WIDGET,
  BASE_URL,
  BILLING_TIME,
  DEBICHECK_SIMULATION,
  API_KEY,
  API_URL,
  MODE,
  ENVIRONMENT,
  LAYUP_LOGO,
  SPLIT_PAYMENT_REDIRECT_URL,
  PASSWORD_RESET_REDIRECT_URL,
  SHOW_BANK_DETAILS,
  UI_BASE_URL,
  DASHBOARDS_URL,
};
