import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { IUser } from './models/auth';
import { UI_BASE_URL } from './constants';

Vue.use(Router);

const routes = [
  {
    path: '',
    redirect: '/login',
    name: 'home',
    props: { transparentHeader: true },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/Login.vue'),
  },
  {
    path: '/password-updated',
    name: 'password-updated',
    component: () => import('@/views/PasswordConfirmation.vue'),
  },
  {
    path: '/mandate-confirmation',
    name: 'mandate-confirmation',
    component: () => import('@/components/payment-plan/Confirmation.vue'),
  },
  {
    path: '/email-verification',
    name: 'email-verification',
    component: () => import('./views/EmailVerification.vue'),
  },
  {
    path: '/otp-verification',
    name: 'otp-verification',
    component: () => import('./views/OtpVerification/OtpVerification.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('./views/ResetPassword.vue'),
  },
  {
    path: '/create-password',
    name: 'create-password',
    component: () => import('./views/CreatePassword.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./views/ForgotPassword/ForgotPassword.vue'),
  },
  {
    path: '/dashboard',
    component: () => import('@/bases/Dashboard.vue'),
    meta: { requireAuth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Home/Home.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'profile',
        name: 'dashboard/profile',
        component: () => import('@/views/dashboard/Profile/Profile.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'purchases',
        name: 'dashboard/purchases',
        component: () => import('@/views/dashboard/Purchases.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'purchases/:id',
        name: 'dashboard/purchases/:id',
        component: () => import('@/views/dashboard/Purchase/Purchase.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'split-payments',
        name: 'dashboard/split-payments',
        component: () => import('@/views/dashboard/SplitPayments.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'support',
        name: 'dashboard/support',
        component: () => import('@/views/dashboard/Support.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'set-default-card',
        name: 'dashboard/set-default-card',
        component: () => import('@/components/profile/CreditCard/NewDefaultCard.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'contact',
        name: 'dashboard/contact',
        component: () => import('@/views/dashboard/Contact.vue'),
        meta: { requireAuth: true },
      },
      {
        path: 'stores',
        name: 'dashboard/stores',
        component: () => import('@/views/dashboard/Stores.vue'),
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: '/order/:id',
    name: 'order',
    component: () => import('@/views/Order.vue'),
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/Payment.vue'),
    meta: { requireAuth: true },
  },
];

const router = new Router({
  mode: 'history',
  base: UI_BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  let authorized;

  if (!to.meta?.requireAuth) {
    authorized = true;
    if (store.getters.authorized === null) {
      store.dispatch('tryLogin');
    }
    if (store.getters.orders === null) {
      await store.dispatch('getOrders');
    }
  } else {
    authorized = await (async () => {
      if (store.getters.authorized === null) {
        store.commit('setLoading', true);
        const success = await store.dispatch('tryLogin');
        store.commit('setLoading', false);
        return success;
      }
      if (store.getters.orders === null) {
        await store.dispatch('getOrders');
      }
      return store.getters.authorized;
    })();
  }

  if (authorized) {
    const isProfileCompleted = (me: IUser | null) => {
      const orders = store.getters.orders as any;
      if (orders && orders.length > 0) {
        return !!(me && me.hasPassword && me.email && me.cellNumber && me.name);
      }
      if (me && !me.hasPassword) return false;
      return true;
    };
    if (
      to.meta?.requireAuth &&
      to.path !== '/dashboard/profile' &&
      to.path !== '/payment' &&
      isProfileCompleted(store.state.me) === false
    ) {
      router.push({ path: '/dashboard/profile' });
    } else {
      next();
    }
  } else {
    router.push({ path: '/login', query: { redirect: to.fullPath } }); // TODO: add redirect
  }
});

export default router;
