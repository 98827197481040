import Vue from 'vue';
import VTooltip from 'v-tooltip';
import Snackbar from 'vuejs-snackbar';
import { getToken } from '@/auth';
import { setHeaderAuthorization } from '@/api';
import * as Sentry from '@sentry/vue';

import './registerServiceWorker';
import './global-components';

import VueMeta from 'vue-meta';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueCurrencyInput from 'vue-currency-input';
import 'vue-nav-tabs/themes/vue-tabs.css';

import 'vue-tel-input/dist/vue-tel-input.css';

import vueCountryRegionSelect from 'vue-country-region-select';
import store from './store';
import router from './router';
import App from './App.vue';

Vue.use(VueSweetalert2);

Vue.use(vueCountryRegionSelect);

Vue.use(VueCurrencyInput);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.component('Snackbar', Snackbar);

Vue.use(VTooltip);

Vue.config.productionTip = false;

const cachedToken = getToken();

if (cachedToken) {
  setHeaderAuthorization(cachedToken);
}

Sentry.init({
  Vue,
  dsn: 'https://1eb8997e77916fae4e46f10255069426@o4505475530358784.ingest.sentry.io/4505652667023360',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
